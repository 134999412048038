<template>
  <div class="HoManagement">
    <div class="HoManagement-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="ho"
              label="Search ho"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <dialog-add-ho-component @refresh="fetchHo" />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="ho"
      :sort-by="sortBy"
      :loading="isLoading"
    >
      <template v-slot:top>
        <dialog-view-ho-component :ho="itemDetail" ref="viewHoDialog" />

        <dialog-edit-ho-component
          @refresh="fetchHo"
          :ho="itemDetail"
          ref="editHoDialog"
        />

        <dialog-delete-ho-component
          @refresh="fetchHo"
          :ho="itemDetail"
          ref="deleteHoDialog"
        />

        <dialog-hub-ho-component :ho="itemDetail" ref="viewHubHoDialog" />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="green" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>

        <v-btn depressed class="mr-2" small color="blue" @click="edit(item)">
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn
          depressed
          small
          class="mr-2"
          color="error"
          @click="deleteItem(item)"
        >
          <v-icon color="white"> mdi-exclamation </v-icon>
        </v-btn>

        <v-btn depressed small color="cyan" @click="hubView(item)">
          <v-icon color="white"> mdi-hubspot </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAddHoComponent from "../../../components/dashboard/settings/DialogAddHoComponent.vue";
import DialogDeleteHoComponent from "../../../components/dashboard/settings/DialogDeleteHoComponent.vue";
import DialogEditHoComponent from "../../../components/dashboard/settings/DialogEditHoComponent.vue";
import DialogViewHoComponent from "../../../components/dashboard/settings/DialogViewHoComponent.vue";
import DialogHubHoComponent from "../../../components/dashboard/settings/DialogHubHoComponent.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "HoManagement",
  components: {
    DialogAddHoComponent,
    DialogViewHoComponent,
    DialogEditHoComponent,
    DialogDeleteHoComponent,
    DialogHubHoComponent,
  },
  data() {
    return {
      ho: "",
      isLoading: false,
      itemDetail: {},
      options: [
        { text: "All", value: "" },
        { text: "Jakarta", value: "Jakarta" },
        { text: "Bandung", value: "Bandung" },
        { text: "Surabaya", value: "Surabaya" },
        { text: "Medan", value: "Medan" },
      ],
      sortBy: ["idHo", "hoName", "city", "status"],
      headers: [
        { text: "ID", value: "idHo", filterable: false },
        { text: "Name", value: "hoName" },
        { text: "Kota", value: "city", filterable: false },
        { text: "Contact", value: "phone", filterable: false },
        { text: "Email", value: "email", filterable: false },
        // { text: "Supervisor", value: "supervisor", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewHoDialog.toggleDialog();
    },
    hubView(item) {
      this.itemDetail = item;
      this.$refs.viewHubHoDialog.toggleDialog();
      this.$refs.viewHubHoDialog.fetchHub(item.idHo);
    },
    edit(item) {
      this.itemDetail = item;
      this.$refs.editHoDialog.toggleDialog();
    },
    deleteItem(item) {
      this.itemDetail = item;
      this.$refs.deleteHoDialog.toggleDialog();
    },
    fetchHo() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Ho`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHO != 0);

          this.items = filteredItem.map((ho) => {
            return {
              idHo: ho.IDHO,
              hoName: ho.NamaHO,
              city: ho.City.NamaCity,
              idCity: ho.City.IDCity,
              phone: ho.Telp,
              email: ho.Email,
              supervisor: ho.Supervisor,
              description: ho.Keterangan,
              address: ho.Alamat,
              status: ho.Status,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
  },
  created() {
    this.fetchHo();
    // this.fetchUserProfile();
  },
};
</script>

<style lang="scss" scoped>
.HoManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
