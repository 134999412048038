<template>
  <div>
    <v-dialog v-model="dialogAddHo" persistent width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          depressed
          v-bind="attrs"
          v-on="on"
          @click="resetForm"
        >
          <v-icon left> mdi-plus </v-icon>
          Buat Komisi HO
        </v-btn>
      </template>

      <v-card>
        <v-card-title> ATUR KOMISI </v-card-title>
        <v-card-subtitle
          >Atur komisi hanya berlaku untuk data di halaman yang sedang
          ditampilkan.</v-card-subtitle
        >

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kota"
                    :rules="requiredRule"
                    :items="cityList"
                    :value="city"
                    @input="setCity"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Nama HO"
                    :rules="requiredRule"
                    :items="dataHO"
                    v-model="ho"
                    item-text="NamaHO"
                    item-value="IDHO"
                    @input="checkKomisi"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Layanan"
                    :rules="requiredRule"
                    :items="['domestic', 'international']"
                    v-model="layanan"
                    @input="setLayanan"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Via"
                    :rules="requiredRule"
                    :items="showVia"
                    v-model="via"
                    item-text="Via"
                    item-value="IDVia"
                    @input="checkKomisi"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row v-if="ho && via">
                <v-col cols="2">
                  <small>Kota</small>
                  <p>{{ city }}</p>
                </v-col>
                <v-col cols="2">
                  <small>Nama HO</small>
                  <p>{{ ho.NamaHO }}</p>
                </v-col>
                <v-col cols="2">
                  <small>Layanan</small>
                  <p>{{ layanan }}</p>
                </v-col>
                <v-col cols="2">
                  <small>Via</small>
                  <p>{{ via.Via }}</p>
                </v-col>
                <v-col cols="2">
                  <small>Komisi Maks (%)</small>
                  <v-text-field outlined dense v-model="komisi"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <small>Action</small>
                  <v-btn class="mb-5" color="primary" @click="saveKomisi" block>
                    Simpan
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogAddHo = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogAddHoComponent",
  props: ["listVia"],
  data() {
    return {
      hoName: "",
      phone: "",
      email: "",
      description: "",
      city: "",
      idCity: "",
      district: "",
      idDistrict: "",
      village: "",
      kecamatan: "",
      kelurahan: "",
      idVillage: "",
      postCode: "",
      address: "",
      dialogAddHo: false,
      snackbar: false,
      snackbarText: "",
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      dataHO: [],
      ho: "",
      layanan: "",
      komisi: 0,
      showVia: [],
      via: "",
      addKomisi: true,
      idKomisi: null,
    };
  },
  methods: {
    setCity(e) {
      this.ho = "";
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);
      axios({
        method: "get",
        url: `${baseUrl}/Ho/FindID/${city.idCity}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.dataHO = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
      if (city) {
        this.idCity = city.idCity;
        this.city = e;
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtName = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtName
      );

      if (village) {
        this.idVillage = village.idKelurahan;
        this.idDistrict = village.idKecamatan;
        this.district = e;
        this.kecamatan = districtName;
        this.kelurahan = villageName;
        this.postCode = village.kodePos;
      }
    },
    setLayanan(e) {
      this.showVia = this.listVia.filter((el) => el.TypeVia == e);
    },
    checkKomisi() {
      if (this.ho.IDHO > 0 && this.via.IDVia) {
        axios({
          method: "get",
          url: `${baseUrl}/KomisiHO/${this.ho.IDHO}/0/${this.via.IDVia}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
          .then((res) => {
            if (res.data.data) {
              this.addKomisi = false;
              this.komisi = res.data.data.HOKomisi;
              this.idKomisi = res.data.data.IDKomisi;
            } else {
              this.addKomisi = true;
              this.komisi = 0;
              this.idKomisi = null;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    saveKomisi() {
      axios({
        method: "post",
        url: `${baseUrl}/Komisi`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          addKomisi: this.addKomisi,
          IDHo: this.ho.IDHO,
          HOKomisi: this.komisi,
          IDVia: this.via.IDVia,
          idKomisi: this.idKomisi,
        },
      })
        .then((res) => {
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.snackbarText = err.message;
          this.isLoading = false;
        })
        .finally(() => {
          this.idKomisi = null;
        });
    },
    resetForm() {
      this.$refs.form.reset();
      // this.dataHO = [];
      // this.ho = "";
      // this.komisi = 0;
      // this.showVia = [];
      // this.city = "";
      // this.via = [];
      // this.layanan = [];
    },
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];

      if (this.idCity) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.idCity;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
