export function formatToRupiah(number) {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(number);
}

export function formatPhone(phone) {
  const isStartWithZero = phone.substr(0, 1) == "0";
  const isStartWithCode = phone.substr(0, 3) == "+62";

  if (isStartWithZero) {
    return `+62${phone.substr(1)}`;
  }

  if (isStartWithCode) {
    return phone;
  }

  return `+62${phone}`;
}

export function reFormatPhone(phone) {
  const isStartWithZero = phone.substr(0, 1) == "0";
  const isStartWithCode = phone.substr(0, 3) == "+62";

  if (isStartWithZero) {
    return phone;
  }

  if (isStartWithCode) {
    return `0${phone.substr(3)}`;
  }

  return `0${phone}`;
}

export function numPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function externalFormatID(profile) {
  const telp = reFormatPhone(profile.Telp);
  const numpad = numPad(profile.KCX_HOIDHO, 4, 0);

  return numpad + telp;
}
