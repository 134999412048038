<template>
  <div class="DialogViewHubComponent">
    <v-dialog
      fullscreen
      v-model="viewHubHoDialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="cyan">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>List Hub {{ ho.hoName }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-container class="mt-5">
            <v-row>
              <v-col sm="4" class="pa-0">
                <v-text-field
                  outlined
                  dense
                  v-model="hub"
                  label="Search hub"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="hub"
                  :sort-by="sortBy"
                  :loading="isLoading"
                >
                  <template v-slot:top>
                    <!-- <dialog-view-hub-component
                      :hub="itemDetail"
                      ref="viewHubDialog"
                    />

                    <dialog-edit-hub-component
                      @refresh="fetchHub"
                      :hub="itemDetail"
                      ref="editHubDialog"
                    />

                    <dialog-delete-hub-component
                      @refresh="fetchHub"
                      :hub="itemDetail"
                      ref="deleteHubDialog"
                    /> -->
                  </template>

                  <template v-slot:[`item.action`]="{}">
                    <!-- <v-btn
                      depressed
                      class="mr-2"
                      small
                      color="green"
                      @click="view(item)"
                    >
                      <v-icon color="white"> mdi-eye </v-icon>
                    </v-btn>

                    <v-btn
                      depressed
                      class="mr-2"
                      small
                      color="blue"
                      @click="edit(item)"
                    >
                      <v-icon color="white"> mdi-pencil </v-icon>
                    </v-btn>

                    <v-btn
                      depressed
                      small
                      color="error"
                      @click="deleteItem(item)"
                    >
                      <v-icon color="white"> mdi-exclamation </v-icon>
                    </v-btn> -->
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogViewHubComponent",
  props: ["ho"],
  data() {
    return {
      viewHubHoDialog: false,
      hub: "",
      isLoading: false,
      itemDetail: {},
      options: [
        { text: "All", value: "" },
        { text: "Jakarta", value: "Jakarta" },
        { text: "Bandung", value: "Bandung" },
        { text: "Surabaya", value: "Surabaya" },
        { text: "Medan", value: "Medan" },
      ],
      sortBy: ["idHub", "hubName", "city", "status"],
      headers: [
        { text: "ID", value: "idHub", filterable: false },
        { text: "Name", value: "hubName" },
        { text: "Kota", value: "city", filterable: false },
        { text: "Contact", value: "phone", filterable: false },
        { text: "Email", value: "email", filterable: false },
        { text: "Supervisor", value: "supervisor", filterable: false },
        { text: "Status", value: "status", filterable: false },
        // { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },

  methods: {
    toggleDialog() {
      this.viewHubHoDialog = !this.viewHubHoDialog;
    },
    fetchHub(idHo) {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Ho/Hub/${idHo}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHub != 0);

          this.items = filteredItem.map((hub) => {
            // console.log(hub);
            return {
              idHub: hub.IDHub,
              hubName: hub.NamaHub,
              city: hub.City.NamaCity,
              idCity: hub.City.IDCity,
              phone: hub.Telp,
              email: hub.Email,
              supervisor: hub.Supervisor,
              description: hub.Keterangan,
              address: hub.Alamat,
              status: hub.Status,
              apiKey: hub.ApiKey,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    closeDialog() {
      this.viewHubHoDialog = false;
      this.items = [];
      this.hub = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
