<template>
  <div class="DropDownManifestComponent">
    <v-data-table :headers="headers" :items="order" hide-default-footer>
      <template v-slot:[`item.resi`]="{ item }">
        {{ item.price3PL ? formatToRupiah(item.price3PL) : formatToRupiah(0) }}
        /
        {{ item.resi3PL ?? "-" }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          class="white--text mx-2"
          color="primary"
          depressed
          small
          @click="track(item)"
        >
          Track
        </v-btn>
        <!-- <v-btn
          depressed
          small
          color="primary"
          @click="sendOne(item)"
          :disabled="item.idStatus >= 12"
        >
          jne
        </v-btn> -->
        <!-- <v-btn
          depressed
          small
          color="orange"
          class="text-white"
          @click="showPOD(item)"
        >
          POD
        </v-btn> -->
        <span
          v-if="
            (item.vendor == 'JNE' || item.vendor == 'SENTRAL') &&
            item.orderType == 'domestic'
          "
        >
          <v-btn
            class="white--text mx-2"
            color="orange"
            depressed
            @click="showPOD(item)"
            small
          >
            POD
          </v-btn>
        </span>
        <span v-if="item.orderType == 'international'">
          <v-btn
            class="white--text mx-2"
            color="orange"
            depressed
            @click="showPODInternational(item)"
            small
          >
            POD
          </v-btn>
        </span>

        &nbsp;
        <v-btn
          depressed
          small
          color="info"
          class="text-white"
          @click="showDetail(item)"
        >
          Detail
        </v-btn>
        &nbsp;
        <v-btn
          depressed
          small
          color="secondary"
          class="text-white"
          @click="showNoref(item)"
        >
          NoRef
        </v-btn>
      </template>
    </v-data-table>
    <br />

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>POD WayBill</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>

        <v-row>
          <v-col v-if="this.photo" class="col-6">
            <v-card>
              <v-img
                :src="this.photo"
                :lazy-src="this.photo"
                aspect-ratio="1"
                class="grey lighten-2 m-5"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-title class="text-h6"> Photo </v-card-title>
            </v-card>
          </v-col>
          <v-col v-else class="d-flex child-flex">
            <div class="text-center mt-5">Tidak Ada Data</div></v-col
          >

          <v-col v-if="this.signature" class="col-6">
            <v-card>
              <v-img
                :src="this.signature"
                :lazy-src="this.signature"
                aspect-ratio="1"
                class="grey lighten-2 m-5"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-title class="text-h6"> Signature </v-card-title>
            </v-card>
          </v-col>
          <v-col v-else class="d-flex child-flex">
            <div class="text-center mt-5">Tidak Ada Data</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDetail"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detail Colly</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>

        <v-row class="p5">
          <v-col class="col-12 p5">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Wb Number</th>
                    <th>ID Koli</th>
                    <th>Panjang</th>
                    <th>Lebar</th>
                    <th>Tinggi</th>
                    <th>Berat Barang</th>
                    <th>Berat Volume</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in collies" :key="item.IDKoli">
                    <td>{{ noAwb }}</td>
                    <td>{{ item.IDKoli }}</td>
                    <td>{{ item.Panjang }}</td>
                    <td>{{ item.Lebar }}</td>
                    <td>{{ item.Tinggi }}</td>
                    <td>{{ item.Berat }}</td>
                    <td>{{ item.BeratVolume }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTracking" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Tracking AWB {{ awbTrack }}</span>
        </v-card-title>
        <v-card-text>
          <div v-if="loadingTrack" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-timeline dense clipped v-if="orderType == 'domestic'">
              <v-timeline-item
                v-for="(t, i) in trackingDetail.slice().reverse()"
                :key="i"
                ><strong>{{ t.code }}</strong> <br />
                <small>{{ t.date }}</small> <br />
                {{ t.desc.replaceAll("JNE", "TROLLY") }}
              </v-timeline-item>
            </v-timeline>
            <v-timeline dense clipped v-else>
              <v-timeline-item
                v-for="(t, i) in trackingDetail.slice().reverse()"
                :key="i"
                ><strong>{{ t.status }}</strong> <br />
                <small>{{ t.dateTime }}</small> <br />
                {{ t.remark.replaceAll("JNE", "TROLLY") }}
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogTracking = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPOD" fullscreen>
      <v-card width="100%" height="100%">
        <v-toolbar dark color="orange">
          <v-btn icon dark @click="dialogPOD = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>POD WayBill International</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <v-row>
            <v-col cols="2" class="ml-2">
              <h4>URL POD :</h4>
            </v-col>

            <v-col cols="4" class="ml-2">
              <v-text-field v-model="linkPOD" outlined dense> </v-text-field>
            </v-col>
            <v-col cols="2" class="ml-2">
              <v-btn depressed color="warning" class="mr-2" @click="updatePOD"
                >Submit</v-btn
              >
            </v-col>
            <v-col cols="2" class="ml-2">
              <v-btn
                v-if="linkPOD"
                depressed
                color="primary"
                class="mr-2"
                @click="openPOD"
                >Open POD</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <!-- <iframe id="ifrm" :src="linkPOD" width="100%" height="100%"></iframe> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNoref" width="600">
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="dialogNoref = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Noref Waybill Vendor</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <v-row>
            <v-col cols="3" class="ml-2">
              <h4>Noref Waybill :</h4>
            </v-col>

            <v-col cols="6" class="ml-2">
              <v-text-field v-model="noRef" outlined dense> </v-text-field>
            </v-col>
            <v-col cols="2" class="ml-2">
              <v-btn depressed color="success" class="mr-2" @click="updateNoref"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { trackingJNE, trackingInternational } from "../../../constant/url";
import { formatToRupiah } from "../../../utils/formatter";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DropDownManifestComponent",
  props: ["order", "fetchManifests"],
  data() {
    return {
      headers: [
        { text: "Order Id", value: "orderId" },
        { text: "Wb Number", value: "wbNumber" },
        { text: "Koli", value: "colly" },
        { text: "Berat", value: "weight" },
        { text: "Price/Resi", value: "resi" },
        { text: "Action", value: "action", sortable: false },
      ],
      dialog: false,
      dialogDetail: false,
      photo: null,
      signature: null,
      snackbar: false,
      snackbarText: "",
      noAwb: "",
      collies: [],
      dialogTracking: false,
      trackingDetail: [],
      loadingTrack: false,
      orderType: "",
      awbTrack: "",
      dialogPOD: false,
      dialogNoref: false,
      linkPOD: "",
      noRef: "",
      idOrder: "",
    };
  },
  methods: {
    // sendOne(item) {
    //   console.log(item);
    // },
    showPOD(awb) {
      this.photo = "Loading";
      this.signature = "Loading";
      this.dialog = true;
      let vendor = awb.vendor == "JNE" ? "JNE" : "SC";
      axios({
        method: "GET",
        url: `${trackingJNE}?carrier=${vendor}&waybill=${awb.wbNumber}`,
      })
        .then((res) => {
          if (awb.vendor == "JNE") {
            if (res.data.data.cnote.photo) {
              this.photo = res.data.data.cnote.photo;
              this.signature = res.data.data.cnote.signature;
            } else {
              this.photo = null;
              this.signature = null;
            }
          } else {
            let response = res.data.data.history.find(
              (i) => i.code == "Delivered"
            );
            if (response) {
              this.photo = response.imgPOD;
              this.signature = null;
            } else {
              this.photo = null;
              this.signature = null;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.snackbar = true;
          this.dialog = false;
          this.snackbarText = "POD Tidak Di Temukan";
        });
    },
    showPODInternational(data) {
      this.idOrder = data.orderId;
      this.dialogPOD = true;
      if (data.detail.LinkPOD) {
        this.linkPOD = data.detail.LinkPOD;
      } else {
        this.linkPOD = null;
      }
    },
    showNoref(data) {
      this.idOrder = data.orderId;
      this.dialogNoref = true;
      if (data.detail.NoRef) {
        this.noRef = data.detail.NoRef;
      } else {
        this.noRef = "";
      }
    },
    openPOD() {
      window.open(this.linkPOD, "_blank", "");
    },
    showDetail(data) {
      this.dialogDetail = true;
      this.collies = data.detail.Collies;
      this.noAwb = data.wbNumber;
    },
    track(data) {
      this.awbTrack = data.wbNumber;
      this.dialogTracking = true;
      this.loadingTrack = true;
      this.trackingDetail = [];
      this.orderType = data.detail.OrderType;
      // console.log(data.wbNumber);
      if (data.detail.OrderType == "international") {
        let vendor = data.detail.Vendor;
        let date = data.detail.DateCreated;
        axios({
          method: "POST",
          url: `${trackingInternational}?carrier=${vendor}&waybill=${data.wbNumber}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            hawb: data.wbNumber,
            vendor: vendor,
            date: date,
            colly: this.order.colly,
          },
        })
          .then((res) => {
            if (res.status == 200) {
              this.loadingTrack = false;
              this.trackingDetail = res.data;
            } else {
              this.loadingTrack = false;
              this.snackbar = true;
              this.dialogTracking = false;
              this.snackbarText = "Tracking Tidak Di Temukan";
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingTrack = false;
            this.snackbar = true;
            this.dialogTracking = false;
            this.snackbarText = "Tracking Tidak Di Temukan";
          });
      } else {
        let vendor = data.detail.Vendor == "JNE" ? "JNE" : "SC";
        axios({
          method: "GET",
          url: `${trackingJNE}?carrier=${vendor}&waybill=${data.wbNumber}`,
        })
          .then((res) => {
            if (res.data.data.history) {
              this.loadingTrack = false;
              this.trackingDetail = res.data.data.history;
            } else {
              this.loadingTrack = false;
              this.snackbar = true;
              this.dialogTracking = false;
              this.snackbarText = "Tracking Tidak Di Temukan";
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingTrack = false;
            this.snackbar = true;
            this.dialogTracking = false;
            this.snackbarText = "Tracking Tidak Di Temukan";
          });
      }
    },
    formatToRupiah(num) {
      return formatToRupiah(num);
    },
    updatePOD() {
      axios({
        method: "PUT",
        url: `${baseUrl}/Orders/updatePOD/${this.idOrder}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,

          "Content-Type": "application/json",
        },
        data: { linkPOD: this.linkPOD },
      })
        .then((res) => {
          if (res.status == 200) {
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
          } else {
            this.snackbar = true;
            this.snackbarText = "POD Tidak Di Temukan";
          }
        })
        .catch((err) => {
          console.log(err);
          this.dialogPOD = true;
          this.snackbar = true;
          this.snackbarText = "POD Tidak Di Temukan";
        });
    },
    updateNoref() {
      axios({
        method: "PUT",
        url: `${baseUrl}/Orders/updateNoref/${this.idOrder}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,

          "Content-Type": "application/json",
        },
        data: { noRef: this.noRef },
      })
        .then((res) => {
          if (res.status == 200) {
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
          } else {
            this.snackbar = true;
            this.snackbarText = "Gagal Update Noref Waybill";
          }
          this.dialogNoref = false;
          this.fetchManifests();
        })
        .catch((err) => {
          console.log(err);
          this.dialogNoref = false;
          this.snackbar = true;
          this.snackbarText = "Gagal Update Noref Waybill";
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
