<template>
  <div class="DialogEditHoComponent">
    <v-dialog v-model="dialogEditHo" persistent max-width="600px">
      <v-card>
        <v-card-title> Update Ho </v-card-title>

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="ho.hoName"
                    outlined
                    dense
                    label="Nama Ho"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="ho.phone"
                    outlined
                    dense
                    label="No. Handphone"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="ho.email"
                    outlined
                    dense
                    label="email"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="ho.description"
                    outlined
                    dense
                    label="Description"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kota"
                    :rules="requiredRule"
                    :items="cityList"
                    :value="ho.city"
                    @input="setCity"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    v-model="ho.address"
                    outlined
                    dense
                    label="Alamat"
                    rows="2"
                    no-resize
                    :rules="requiredRule"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn color="warning" depressed @click="updateHo"> update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import cityData from "../../../data/city.json";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogEditHoComponent",
  props: ["ho"],
  data() {
    return {
      dialogEditHo: false,
      valid: false,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogEditHo = !this.dialogEditHo;
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.ho.idCity = city.idCity;
        this.ho.city = e;
      }
    },
    updateHo() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "put",
          url: `${baseUrl}/Ho/${this.ho.idHo}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            NamaHo: this.ho.hoName,
            Email: this.ho.email,
            Telp: this.ho.phone,
            Alamat: this.ho.address,
            Keterangan: this.ho.description,
            CityIDCity: this.ho.idCity,
          },
        })
          .then((res) => {
            this.toggleDialog();
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
  },
};
</script>

<style lang="scss" scoped></style>
