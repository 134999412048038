<template>
  <div class="Wallet">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3" outlined tile>
          <div>
            <h3>Topup Masterhub</h3>
            <small>History Topup Masterhub</small>
          </div>
          <div class="mt-3">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="transaction"
                      :search="search"
                    >
                      <template v-slot:item.Date="{ item }">
                        <span>{{ formatDate(item.Date) }}</span>
                      </template>
                      <template v-slot:item.Amount="{ item }">
                        <span v-if="item.IsDebit" class="green--text"
                          ><strong>{{
                            formatToRupiah(item.Amount)
                          }}</strong></span
                        >
                        <span v-else class="red--text"
                          ><strong>{{
                            formatToRupiah(item.Amount)
                          }}</strong></span
                        >
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";

export default {
  name: "Wallet",
  data: () => ({
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    headers: [
      { text: "Nama HO", value: "Ho.NamaHO" },
      { text: "Telp", value: "Ho.Telp" },
      { text: "Trx. ID", value: "TransactionID" },
      {
        text: "Date",
        align: "start",
        filterable: false,
        value: "Date",
      },
      { text: "Trx. Type", value: "TransactionType" },
      { text: "Amuount", value: "Amount" },
      { text: "Order ID", value: "OrderID" },
    ],
    listBank: [],
    listVA: [],
    transaction: [],
    transactionIn: [],
    transactionOut: [],
    dataVA: {
      bankCode: "",
      amount: 500000,
      name: getProfileCookie().NamaDepan,
      description: "TOPUP HO",
    },
    amount: 500000,
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    externalID: externalFormatID(getProfileCookie()),
  }),
  created() {
    this.fetchTransaction();
  },
  computed: {},
  watch: {},
  methods: {
    async fetchTransaction() {
      this.isLoading = true;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/Transaction/Topup`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.transaction = res.data.data;
      this.transactionIn = res.data.data.filter((el) => el.IsDebit == true);
      this.transactionOut = res.data.data.filter((el) => el.IsDebit == false);
      console.log(res.data.data);
      this.isLoading = false;
    },

    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("L");
    },
  },
};
</script>

<style lang="scss" scoped></style>
