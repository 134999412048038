<template>
  <div class="HubManagement">
    <v-dialog v-model="dialogViewHub" persistent max-width="1000px">
      <v-card>
        <v-card-title> List Hub </v-card-title>
        <v-card-text>
          <div class="HubManagement-Option">
            <v-container>
              <v-row>
                <v-col sm="4" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    v-model="hub"
                    label="Search hub"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <v-data-table
            :headers="headers"
            :items="items"
            :search="hub"
            :sort-by="sortBy"
            item-key="idHub"
            :loading="isLoading"
            :expanded.sync="expanded"
            :single-expand="true"
            show-expand
            @item-expanded="loadDetails"
          >
            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length" class="pa-0">
                <drop-down-detail-komisi
                  :komisi="item"
                  :detailKomisi="komisiDetail"
                />
              </td>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" outlined @click="toggleDialog">cancel</v-btn> -->

          <v-btn color="warning" depressed @click="toggleDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DropDownDetailKomisi from "../../../components/dashboard/module/DropDownDetailKomisi.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";

export default {
  name: "HubManagement",
  props: ["ho"],
  components: {
    DropDownDetailKomisi,
  },
  data() {
    return {
      hub: "",
      isLoading: false,
      sortBy: ["idHub", "hubName", "city", "status"],
      headers: [
        { text: "Name", value: "hubName" },
        { text: "Kota", value: "city", filterable: false },
        { text: "Contact", value: "phone", filterable: false },
        { text: "Email", value: "email", filterable: false },
        { text: "Status", value: "status", filterable: false },
        // { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      listVia: [],
      expanded: [],
      dKomisi: [],
      detail: [],
      dialogViewHub: false,
      idHO: getProfileCookie().KCX_HOIDHO,
    };
  },
  methods: {
    fetchHub(idho) {
      this.isLoading = true;
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          idho: idho,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHub != 0);

          this.items = filteredItem.map((hub) => {
            // console.log(hub);
            return {
              idHub: hub.IDHub,
              hubName: hub.NamaHub,
              city: hub.City.NamaCity,
              idCity: hub.City.IDCity,
              phone: hub.Telp,
              email: hub.Email,
              supervisor: hub.Supervisor,
              description: hub.Keterangan,
              address: hub.Alamat,
              status: hub.Status,
              apiKey: hub.ApiKey,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },

    loadDetails({ item }) {
      axios({
        method: "get",
        url: `${baseUrl}/Komisi/Detail/${this.idHO}/${item.idHub}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          if (res.data.data) {
            this.dKomisi = res.data.data;
          } else {
            this.dKomisi = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.dKomisi = [];
        });
    },
    toggleDialog() {
      this.dialogViewHub = !this.dialogViewHub;
    },
  },
  computed: {
    komisiDetail() {
      let detail = [];
      for (let i = 0; i < this.dKomisi.length; i++) {
        detail.push({
          HOKomisi: this.dKomisi[i].HOKomisi,
          HubKomisi: this.dKomisi[i].HUBKomisi,
          Via: this.dKomisi[i].Vium.Via,
          TypeVia: this.dKomisi[i].Vium.TypeVia,
        });
      }

      return detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.HubManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
