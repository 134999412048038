<template>
  <div>
    <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
      :value="showAlert"
    >
      {{ message }}
    </v-alert>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="container ma-0 mr-0 form-financial"
    >
      <div class="d-flex flex-row justify-space-between">
        <div class="d-flex flex-row align-start">
          <v-text-field
            class="mr-2"
            v-model="startDate"
            type="date"
            outlined
            label="From"
            dense
            :rules="[(v) => !!v || 'You must pick date from to searching!']"
          ></v-text-field>

          <v-text-field
            v-model="endDate"
            class="mr-4"
            type="date"
            outlined
            label="To"
            dense
            :rules="[(v) => !!v || 'You must pick date to for searching!']"
          ></v-text-field>

          <v-btn
            depressed
            color="cyan"
            class="mr-2 white--text"
            @click="submit"
          >
            Search
          </v-btn>
        </div>
        <div>
          <download-excel
            :data="datas"
            :name="excelFileName"
            :fields="jsonFields"
          >
            <v-btn depressed color="primary">Download Data</v-btn>
          </download-excel>
        </div>
      </div>
    </v-form>

    <v-data-table
      :headers="headers"
      :items="datas"
      :search="idOrder"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
      }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2 white--text"
          small
          color="cyan"
          @click="showDetail(item)"
        >
          detail
        </v-btn>
      </template>
    </v-data-table>

    <dialog-detail-financial-report
      ref="detailFinancialReport"
      :propDetail="selectedData"
      :userProfile="userProfile"
    />
  </div>
</template>

<script>
import moment from "moment";
import { formatToRupiah } from "../../../utils/formatter";
import { getFinancialReport } from "../../../services/hub";
import { getUserProfile } from "../../../services/user";
import DialogDetailFinancialReport from "./DialogDetailFinancialReport.vue";
export default {
  name: "FinancialReport",
  components: {
    DialogDetailFinancialReport,
  },
  data: () => ({
    headers: [
      { text: "Tanggal Transaksi", value: "date" },
      { text: "ID HO", value: "idHo", filterable: false },
      { text: "ID HUB", value: "idHub" },
      { text: "Service", value: "service", filterable: false },
      { text: "Produk", value: "", filterable: false },
      { text: "Qty", value: "total_qty", filterable: false },
      { text: "Ongkos Kirim", value: "ongkir", filterable: false },
      { text: "Premi", value: "insurance", filterable: false },
      { text: "PPN", value: "total_ppn", filterable: false },
      { text: "Total", value: "totalAmount", filterable: false },
      { text: "Action", value: "action", filterable: false },
    ],
    jsonFields: {
      "Tanggal Transaksi": "date",
      "ID HO": "idHo",
      "ID HUB": "idHub",
      Service: "service",
      Produk: "",
      Qty: "total_qty",
      "Ongkos Kirim": "ongkir",
      Premi: "insurance",
      PPN: "total_ppn",
      Total: "totalAmount",
    },
    datas: [],
    startDate: "",
    endDate: "",
    showAlert: false,
    message: "",
    selectedData: {},
    userProfile: {},
  }),
  computed: {
    excelFileName() {
      const startDate = moment(this.startDate).format("DDMMYYYY");
      const endDate = moment(this.endDate).format("DDMMYYYY");
      return `Financial${this.userProfile.KCX_HOIDHO}-${startDate}to${endDate}`;
    },
  },
  async mounted() {
    const now = moment().format("YYYY-MM-DD");
    const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    const resp = await getUserProfile();
    this.startDate = now;
    this.endDate = tomorrow;
    this.getData(now, tomorrow);
    this.userProfile = resp;
  },
  methods: {
    async getData(startDate, endDate) {
      const resp = await getFinancialReport(startDate, endDate);
      this.datas = resp.map((v) => {
        return {
          plain_date: v.date,
          date: moment(v.date).format("DD/MM/YYYY"),
          idHub: v.IDHub,
          idHo: v.IDHo,
          totalAmount: formatToRupiah(parseFloat(v.total_amount)),
          insurance: v.insurance,
          total_qty: parseInt(v.total_qty),
          total_ppn: parseFloat(v.total_ppn),
          service: "Darat",
          ongkir: formatToRupiah(
            parseFloat(v.total_amount) - v.insurance - parseFloat(v.total_ppn)
          ),
        };
      });
    },
    submit() {
      this.showAlert = false;
      const startDt = moment(this.startDate);
      const endDt = moment(this.endDate);
      if (startDt > endDt) {
        this.message = "your end date must before the start date";
        this.showAlert = true;
        return;
      }

      this.getData(
        this.startDate,
        moment(this.endDate).add(1, "days").format("YYYY-MM-DD")
      );
    },
    showDetail(item) {
      this.selectedData = item;
      this.$refs.detailFinancialReport.toggleDialog();
    },
  },
};
</script>

<style scoped>
.form-financial {
  max-width: 100% !important;
  width: 100%;
}
</style>
